import bannerLunesPromo from "@/assets/banners/es/Farolito_Feb2025_BannerWeb_Lunes3x2.png";
import bannerMartesPromo from "@/assets/banners/es/Farolito_Feb2025_BannerWeb-Martes-PASTOR.png";
import bannerJuevesPromo from "@/assets/banners/es/Farolito_Feb2025_BannerWeb-Jueves_GRINGA.png";

import bannerFideoEspecial from "@/assets/banners/es/Farolito_Ene2025_Fideo.jpg";

import bannerWebKilos from "@/assets/banners/es/fto_bannerweb_kilos_2880x1145px.png";

import bannerLineaTaco from "@/assets/banners/es/fto_bannerweb_linea_taco_2880x1145px.png";


const pathImgBanner = [
  {
    id: "1",
    imgEsp: bannerLunesPromo,
    imgEng: bannerLunesPromo,
  },
  {
    id: "2",
    imgEsp: bannerMartesPromo,
    imgEng: bannerMartesPromo,
  },
  {
    id: "3",
    imgEsp: bannerJuevesPromo,
    imgEng: bannerJuevesPromo,
  },
  {
    id: "4",
    imgEsp: bannerFideoEspecial,
    imgEng: bannerFideoEspecial,
  },
  {
    id: "5",
    imgEsp: bannerWebKilos,
    imgEng: bannerWebKilos,
  },
  {
    id: "6",
    imgEsp: bannerLineaTaco,
    imgEng: bannerLineaTaco,
  },
];

export default pathImgBanner;
