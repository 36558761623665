<template>
  <div style="display: flex; justify-content: center; gap: 2rem">

      <button type="button" > 
        <a href="https://api.whatsapp.com/send?phone=525551053532 " target="_blank">
          <img v-if="this.$i18n.locale == 'es'" src="@/assets/buttonsPop/btnesp/whats_esp.png" alt="slogan" style="width:7.5vw; height:10vw; ">
          <img v-else src="@/assets/buttonsPop/btning/whats_eng.png" alt="slogan" style="width:7.5vw; height:10vw; ">
        </a>
      </button>
      
      <button type="button" >
        <a href=" tel:8008226011 " target="_blank">
          <img v-if="this.$i18n.locale == 'es'" src="@/assets/buttonsPop/btnesp/taco_esp.png" alt="slogan" style="width:7.5vw; height:10vw; ">
          <img v-else src="@/assets/buttonsPop/btning/taco_eng.png" alt="slogan" style="width:7.5vw; height:10vw; ">  
        </a>
      </button> 
      <button type="button" >
        <a href="https://www.rappi.com.mx/" target="_blank">
          <!-- <img src="@/assets/buttonsPop/boton_rappi_vertical.png" style="width:7.5vw; height:10vw; "> -->
            <img v-if="this.$i18n.locale == 'es'" src="@/assets/buttonsPop/btnesp/rappi_esp.png" alt="slogan" style="width:7.5vw; height:10vw; ">
            <img v-else src="@/assets/buttonsPop/btning/rappi_eng.png" alt="slogan" style="width:7.5vw; height:10vw; ">
        </a>
      </button> 
      <button type="button" >
        <a href=" https://www.ubereats.com/mx/brand/el-farolito?&utm_campaign=el-farolito-mx&utm_source=instagram&utm_medium=brandpage " target="_blank">
          <img v-if="this.$i18n.locale == 'es'" src="@/assets/buttonsPop/btnesp/uber_esp.png" alt="slogan" style="width:7.5vw; height:10vw; ">
          <img v-else src="@/assets/buttonsPop/btning/uber_eng.png" alt="slogan" style="width:7.5vw; height:10vw; ">  
        </a>
      </button>
      <button type="button" >
        <a href=" " target="_blank">
          <img v-if="this.$i18n.locale == 'es'" src="@/assets/buttonsPop/btnesp/didi_esp.png" alt="slogan" style="width:7.5vw; height:10vw; ">
          <img v-else src="@/assets/buttonsPop/btning/didi_eng.png" alt="slogan" style="width:7.5vw; height:10vw; ">  
        </a>
      </button>
  </div>
</template>
<script>
export default {
      name: 'BotonesPop',
    }
</script>
