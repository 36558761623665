<template>
  <v-card max-width="100%" class="mx-auto banner "  >
    <v-carousel cycle :continuous="true"
      :show-arrows="false"
      hide-delimiter-background
      delimiter-icon="mdi-square"
      height= "">
      <v-carousel-item v-for="(banner) in banners" :key="banner.id" :transition="fade - transition">
        <!-- <div v-if="i == 1" > 
          <img v-if="this.$i18n.locale == 'es'" src="@/assets/banners/es/fto_bannerweb_kilos_2880x1145px.png" >
          <img v-else src="@/assets/banners/es/fto_bannerweb_kilos_2880x1145px.png" >
          <pop5 @click="bluekai('FTO_Banner_home','Banner_Home_3')"/>
        </div> -->
        <div> 
          <img v-if="this.$i18n.locale == 'es'" :src="banner.imgEsp" class="img_banner">
          <img v-else :src="banner.imgEsp" >
        </div>
        
      </v-carousel-item>
      <Botonesbanner />
    </v-carousel>
  </v-card>

  

</template>

<script>
import Botonesbanner from '@/components/Botonesbanner.vue'
import BotonesPop from '@/components/BotonesPop.vue'
import pop1 from '@/bannerPop/pop1.vue'
import pop2 from '@/bannerPop/pop2.vue'
import pop3 from '@/bannerPop/pop3.vue'
import pop4 from '@/bannerPop/pop4.vue'
import pop5 from '@/bannerPop/pop5.vue'
import pop6 from '@/bannerPop/pop6.vue'

import pathImgBanner from '@/scripts/pathsImgBanners';
export default {
  name: 'Home',
  components: {
    Botonesbanner, BotonesPop,pop1,pop2,pop3,pop4,pop5,pop6,
  }, 
  data () {
      return {
        dialog: false,
        banners: pathImgBanner
      }
    },
  methods: {
    bluekai(key, value) {
      bk_addPageCtx(key, value);
      bk_doJSTag(93939, 1);
    }
  }
}
</script>

<style scoped >
.button {
  width: 11.7rem;
  height: 2.7rem;
  font-size: 16px;
}
.img_banner{
  aspect-ratio: 6/2;
}

@media screen and (min-width: 600px) {
  .button {
    width: 16.7rem;
    height: 4.7rem;
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .button {
    width: 31.7rem;
    height: 6.7rem;
    font-size: 3.2rem;
  }
}
</style>